import Header from './components/header'
import Products from './components/products'
import About from './components/about'
import Application from './components/application'
import Contacts from './components/contacts'
import Advantages from './components/advantages'
import {
  useEffect,
  useState
} from 'react'

const App = () => {
  const [smDevice, setSmDevice] = useState(
    window.matchMedia('(max-width: 768px)').matches
  )
  const [mdDevice, setMdDevice] = useState(
    window.matchMedia('(max-width: 960px)').matches
  )

  useEffect(() => {
    document.querySelector('meta[name="description"]').setAttribute('content', 'Доступні системи тепловізійного бачення для FPV та кастомних безпілотників (БПЛА) від українського виробника SeekUAV. ➤ Замовляйте з доставкою по Україні.')
    document.title = 'Тепловізійні системи для дронів | Seek UAV'
    window
      .matchMedia('(max-width: 768px)')
      .addEventListener('change', e => setSmDevice(e.matches))
    window
      .matchMedia('(max-width: 960px)')
      .addEventListener('change', e => setMdDevice(e.matches))
  }, [])

  return (
    <div className="App">
      <Header smDevice={smDevice} mdDevice={mdDevice} />
      <Advantages />
      <Products />
      <About />
      <Application smDevice={smDevice} />
      <Contacts smDevice={smDevice} />
    </div>
  )
}

export default App
