import {
  useRef
} from 'react'
import './index.scss'
import Slider from 'react-slick'

const Application = ({ smDevice }) => {
  let sliderRef = useRef(null)
  const items = [
    { img: '1' },
    { img: '2' },
    { img: '3' },
    { img: '4' },
    { img: '5' },
    { img: '6' },
    { img: '7' },
    { img: '8' },
    { img: '9' },
    { img: '10' }
  ]
  const settings = {
    className: 'slider variable-width',
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true
  }

  const next = () => {
    sliderRef.slickNext()
  }

  const previous = () => {
    sliderRef.slickPrev()
  }

  return (
    <section className="application" id="application">
      <div className="container">
        <h3 className="title">Застосування</h3>
      </div>
      <div>
        <Slider
          ref={slider => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {items.map((item, index) => {
            return (
              <div className="application__item" key={index}>
                <img
                  className="application__img"
                  src={require(`./img/${item.img}.jpg`)}
                  srcSet={`${require(`./img/${item.img}@2x.jpg`)} 2x`}
                />
              </div>
            )
          })}
        </Slider>
        {!smDevice && (
          <div className="application__buttons-wrapper">
            <button className="button-outlined" onClick={previous}>
              <svg width="15" height="10" viewBox="0 0 15 10" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2 5L1.29289 4.29289L0.585786 5L1.29289 5.70711L2 5ZM14 6C14.5523 6 15 5.55228 15 5C15 4.44772 14.5523 4 14 4V6ZM5.29289 0.292893L1.29289 4.29289L2.70711 5.70711L6.70711 1.70711L5.29289 0.292893ZM1.29289 5.70711L5.29289 9.70711L6.70711 8.29289L2.70711 4.29289L1.29289 5.70711ZM2 6H14V4H2V6Z" />
              </svg>
            </button>
            <button className="button-outlined" onClick={next}>
              <svg width="15" height="10" viewBox="0 0 15 10" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13 5L13.7071 4.29289L14.4142 5L13.7071 5.70711L13 5ZM1 6C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4V6ZM9.70711 0.292893L13.7071 4.29289L12.2929 5.70711L8.29289 1.70711L9.70711 0.292893ZM13.7071 5.70711L9.70711 9.70711L8.29289 8.29289L12.2929 4.29289L13.7071 5.70711ZM13 6H1V4H13V6Z" />
              </svg>
            </button>
          </div>
        )}
      </div>
    </section>
  )
}

export default Application