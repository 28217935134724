import './index.scss'

const ProductVideo = ({ productId }) => {
  const videos = {
    1: 'https://www.youtube.com/embed/yXRiTNRFz8E?si=E-E5YSTUMnqPtkbA',
    2: 'https://www.youtube.com/embed/yXRiTNRFz8E?si=E-E5YSTUMnqPtkbA',
    3: 'https://www.youtube.com/embed/BrPOvDQa-4w?si=XtEA-yXZ6G8WM38V',
    4: 'https://www.youtube.com/embed/jIAm1VmzyxU?si=4YuxNqhqIuFy_CZP'
  }
  return (
    <section className="product-video">
      <h3 className="title">Відео</h3>
      <div className="product-video__video-wrapper">
        <iframe
          src={videos[productId]}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </div>
    </section>
  )
}

export default ProductVideo