import React from 'react'
import { createRoot } from 'react-dom/client'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles/fonts.css'
import './styles/index.scss'
import App from './App.js'
import ProductPage from './components/product-page'
import reportWebVitals from './reportWebVitals'
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />
  },
  {
    path: 'product-1',
    element: <ProductPage productId={1} />
  },
  {
    path: 'product-2',
    element: <ProductPage productId={2} />
  },
  {
    path: 'product-3',
    element: <ProductPage productId={3} />
  },
  {
    path: 'product-4',
    element: <ProductPage productId={4} />
  }
])

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
