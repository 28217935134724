import './index.scss'
import { HashLink as Link } from 'react-router-hash-link'

const MenuNav = ({ mobile, onLinkClick }) => {
  const menuItems = [
    {
      text: 'Головна',
      link: '/#header'
    },
    {
      text: 'Продукція',
      link: '/#products'
    },
    {
      text: 'Про нас',
      link: '/#about'
    },
    {
      text: 'Контакти',
      link: '#contacts'
    }
  ]

  return (
    <nav className={`menu-nav ${mobile ? 'menu-nav--mobile' : ''}`} id="#nav">
      <ul>
        {menuItems.map((item, index) => {
          return (
            <li key={index}>
              <Link
                to={item.link}
                className="menu-nav__link"
                onClick={onLinkClick}
              >
                {item.text}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default MenuNav