import ProductOne from '../product-page/products-info/ProductOne'
import ProductTwo from '../product-page/products-info/ProductTwo'
import ProductThree from '../product-page/products-info/ProductThree'
import ProductFour from '../product-page/products-info/ProductFour'

const productsItems = [
  {
    title: 'Тепловізор Seek UAV 256',
    link: '/product-1',
    id: 1,
    component: <ProductOne productId={1} />,
    price: 12000
  },
  {
    title: 'Тепловізійний комплект Seek UAV 256 Duo',
    link: '/product-2',
    id: 2,
    component: <ProductTwo productId={2} />,
    price: 15000
  },
  {
    title: 'Плата-конвертер Seek UAV DM V4',
    link: '/product-3',
    id: 3,
    component: <ProductThree productId={3} />,
    price: 2500
  },
  {
    title: 'Тепловізійний комплекс для дронів DJI та AUTEL',
    link: '/product-4',
    id: 4,
    component: <ProductFour productId={4} />,
    price: 20000
  }
]

export default productsItems
