import './index.scss'
import {
  Link
} from 'react-router-dom'
import productsItems from './productsItems'

const Products = () => {
  return (
    <section className="products" id="products">
      <div className="container">
        <h3 className="title">Продукція</h3>

        <div className="products-cards">
          {productsItems.map((item, index) => {
            const productNum = index + 1
            return (
              <Link to={item.link} className="products-cards__item" key={index}>
                <img
                  className="products-cards__img"
                  src={require(`../../img/products/product-${productNum}/img-1.jpeg`)}
                  srcSet={`${require(`../../img/products/product-${productNum}/img-1@2x.jpeg`)} 2x`}
                />
                <div className="products-cards__content">
                  <h4 className="products-cards__title">
                    {item.title}
                  </h4>
                  <h4 className="products-cards__title products-cards__title--price">
                    {item?.id === 4 ? 'від ' : ''}{item.price} грн
                  </h4>
                  <Link to={item.link} className="button-main button-main--small">
                    Детальніше
                  </Link>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Products