import './index.scss'
import fb from './img/fb.svg'
import yt from './img/yt.svg'
import email from './img/email.svg'

const SocialButtons = () => {
  return (
    <div className="social-buttons">
      <a href="https://www.facebook.com/seekuav" target="_blank" className="social-buttons__button">
        <img src={fb} alt="Facebook" />
      </a>
      <a href="https://www.youtube.com/@seekuav" target="_blank" className="social-buttons__button">
        <img src={yt} alt="YouTube" />
      </a>
      <a href="mailto:info@seekuav.com" target="_blank" className="social-buttons__button">
        <img src={email} alt="Email" />
      </a>
    </div>
  )
}

export default SocialButtons