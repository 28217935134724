import './index.scss'
import Contacts from '../contacts'
import {
  useEffect,
  useState
} from 'react'
import Menu from '../menu'
import ProductVideo from './ProductVideo'
import ProductMore from './ProductsMore'
import ProductCard from './ProductCard'
import { useLocation } from 'react-router-dom'
import productItems from '../products/productsItems'

const ProductPage = ({ productId }) => {
  const { pathname } = useLocation()

  const [smDevice, setSmDevice] = useState(
    window.matchMedia('(max-width: 768px)').matches
  )
  const [mdDevice, setMdDevice] = useState(
    window.matchMedia('(max-width: 960px)').matches
  )

  useEffect(() => {
    const product = productItems.filter(i => i?.id === productId)[0]
    if (product) {
      const desc = `Купити тепловізори від Seek UAV: ${product?.title} ➤ Замовляйте з доставкою по Україні.`
      document.querySelector('meta[name="description"]').setAttribute('content', desc)
      document.title = `${product?.title} | Seek UAV`
    }
    window
      .matchMedia('(max-width: 768px)')
      .addEventListener('change', e => setSmDevice(e.matches))
    window
      .matchMedia('(max-width: 960px)')
      .addEventListener('change', e => setMdDevice(e.matches))
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <main className="product-page">
      <Menu mdDevice={mdDevice} />
      <div className="container">
        <ProductCard productId={productId} />
        <ProductVideo productId={productId} />
        <ProductMore productId={productId} />
      </div>
      <Contacts smDevice={smDevice} />
    </main>
  )
}

export default ProductPage