import './index.scss'
import viber from './img/viber.svg'
import telegram from './img/telegram.svg'
import whatsapp from './img/whatsapp.svg'
import signal from './img/signal.svg'

const SupportButton = ({ mobile }) => {
  return (
    <div className={`support-button ${mobile ? 'mobile' : ''}`}>
      <div className="support-button__btn">
        Написати нам
      </div>
      <div className="support-button__menu">
        <div className="support-button__menu-content">
          <a
            className="support-button__menu-item"
            href="viber://chat?number=+380967303063"
            target="_blank"
          >
            <div className="support-button__social-btn">
              <img src={viber} alt="Email" />
            </div>
            <span>Написати в Viber</span>
          </a>
          <a
            className="support-button__menu-item"
            href="https://t.me/SeekUAV"
            target="_blank"
          >
            <div className="support-button__social-btn">
              <img src={telegram} alt="Telegram" />
            </div>
            <span>Написати в Telegram</span>
          </a>
          <a
            className="support-button__menu-item"
            href="whatsapp://send?phone=+380967303063"
            target="_blank"
          >
            <div className="support-button__social-btn">
              <img src={whatsapp} alt="Telegram" />
            </div>
            <span>Написати в WhatsApp</span>
          </a>
          {/*<a*/}
          {/*  className="support-button__menu-item"*/}
          {/*  href="#"*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  <div className="support-button__social-btn">*/}
          {/*    <img src={signal} alt="Telegram" />*/}
          {/*  </div>*/}
          {/*  <span>Написати в Signal</span>*/}
          {/*</a>*/}
          <div className="support-button__divider" />
          <p className="support-button__label">
            Режим роботи (9:00-21:00)
          </p>
        </div>
      </div>
    </div>
  )
}

export default SupportButton